import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-toledo-ohio.png'
import image0 from "../../images/cities/scale-model-of-greetings-from-toledo-in-toledo-ohio.png"
import image1 from "../../images/cities/scale-model-of-toledo-zoo-in-toledo-ohio.png"
import image2 from "../../images/cities/scale-model-of-toledo-firefighters-museum-in-toledo-ohio.png"
import image3 from "../../images/cities/scale-model-of-tourism-information-in-toledo-ohio.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Toledo'
            state='Ohio'
            image={image}
            lat='41.6528052'
            lon='-83.53786739999998'
            attractions={ [{"name": "Greetings From Toledo", "vicinity": "120 Main St, Toledo", "types": ["point_of_interest", "establishment"], "lat": 41.64786910000001, "lng": -83.52313520000001}, {"name": "Toledo Zoo", "vicinity": "2 Hippo Way, Toledo", "types": ["zoo", "point_of_interest", "establishment"], "lat": 41.6216435, "lng": -83.58254260000001}, {"name": "Toledo Firefighters Museum", "vicinity": "918 W Sylvania Ave, Toledo", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.6929431, "lng": -83.56414000000001}, {"name": "Tourism Information", "vicinity": "401 Jefferson Ave # B, Toledo", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.6499118, "lng": -83.5361413}] }
            attractionImages={ {"Greetings From Toledo":image0,"Toledo Zoo":image1,"Toledo Firefighters Museum":image2,"Tourism Information":image3,} }
       />);
  }
}